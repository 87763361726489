import React, { useState } from "react";
import styled from "styled-components";
import "rsuite/dist/rsuite.min.css";
import { COLOR_PALETTE } from "../../shared/colors";
import config from "../../config.json";
import {
  Box,
  Button,
  FormControl,
  Input,
  FormErrorMessage,
  Text,
  Spinner,
} from "@chakra-ui/react"; // Import Spinner
import axios from "axios";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically */
  height: 100vh; /* Full height of the viewport */
  background-color: ${COLOR_PALETTE.background};
`;

const schema = yup.object().shape({
  email: yup.string().email("Invalid email address").required("Email is required"),
});

export const ForgotPassword = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false); // State for loading spinner

  const onSubmit = async (data) => {
    setLoading(true); // Show the spinner
    try {
      const response = await axios.post(
      `${config.baseURL}/users/password-reset/`,
        { email: data.email },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setResponseMessage(response.data.message); // Assuming the response contains a 'message' field
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setResponseMessage(error.response.data.message);
      } else {
        setResponseMessage("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false); // Hide the spinner
    }
  };

  return (
    <ForgotPasswordContainer>
      <Text fontSize="2xl" color="white" mb={4}>
        Reset Your Password
      </Text>
      <Box w={"70vw"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.email} mb={4}>
            <Input
              backgroundColor={"white"}
              id="email"
              placeholder="Enter your email"
              {...register("email")}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <Button width="full" mt={4} colorScheme="teal" type="submit" disabled={loading}>
            Submit
          </Button>
        </form>
      </Box>

      {loading && (
        <Spinner color="white" mt={4} /> // Display the spinner when loading
      )}

      {responseMessage && !loading && (
        <Text color="white" mt={4}>
          {responseMessage}
        </Text>
      )}
    </ForgotPasswordContainer>
  );
};
