import React, { createContext, useState, useEffect } from "react";
import axios from "../apiUtils"; // Import your Axios instance
import toast from "react-hot-toast";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    token: localStorage.getItem("token"),
    refreshToken: localStorage.getItem("refresh_token"),
    isAuthenticated: !!localStorage.getItem("token"),
  });

  useEffect(() => {
    const checkTokenValidity = async () => {
      // Add logic to verify token validity and refresh if needed
    };

    checkTokenValidity();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post("login/", { email, password });
      const { access, refresh } = response.data;
      localStorage.setItem("token", access);
      localStorage.setItem("refresh_token", refresh);
      setAuth({
        token: access,
        refreshToken: refresh,
        isAuthenticated: true,
      });
      return response;
    } catch (error) {
      toast.error(error.response.data.error || "Login failed.");
      throw new Error(error.response.data.error || "Login failed.");
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    setAuth({
      token: null,
      refreshToken: null,
      isAuthenticated: false,
    });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
