import React, { useState } from "react";
import { 
  Box, Container, VStack, Image, Heading, Text, 
  Button, Alert, AlertIcon, useToast, Flex, Tabs,
  TabList, TabPanels, Tab, TabPanel, Divider
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { COLOR_PALETTE } from "../../shared/colors";
import logo from "../../assets/img/Logo.png";
  
export const PolicyConsent = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleAgree = async () => {
    setIsLoading(true);
    try {
      toast({
        title: "Success",
        description: "Thank you for accepting our policies",
        status: "success",
        duration: 3000,
      });
      navigate("/login");
    } catch (error) {
      toast({
        title: "Error",
        description: "Something went wrong. Please try again.",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box minHeight="100vh" backgroundColor={COLOR_PALETTE.background} py={4} px={4}>
      <Container maxW="container.md">
        <VStack spacing={6} align="stretch">
          <Flex justify="center" mt={4} mb={6}>
            <Image src={logo} alt="Logo" maxW="200px" w="50%" objectFit="contain" />
          </Flex>

          <Box backgroundColor="white" borderRadius="md" p={6}>
            <Tabs isFitted variant="enclosed">
              <TabList mb="1em">
                <Tab>Privacy Policy</Tab>
                <Tab>Refund Policy</Tab>
                <Tab>Service Policy</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <VStack align="stretch" spacing={4}>
                    <Heading size="md">Privacy Policy</Heading>
                    
                    <Text mb={4}>
                      This privacy policy outlines the principles and practices we follow to protect the personal data of cardholders when using our e-commerce platform. Your privacy and security are our top priorities.
                    </Text>

                    <Box>
                      <Text fontWeight="bold" mb={2}>1. Data Collection</Text>
                      <Text mb={2}>We collect only the personal data necessary to process card transactions and fulfill our legal obligations. This data may include:</Text>
                      <VStack align="stretch" pl={4} spacing={1} mb={4}>
                        <Text>• Cardholder's full name</Text>
                        <Text>• Billing address</Text>
                        <Text>• Card information (card number, expiration date, CVV code)</Text>
                        <Text>• Email address and phone number for contact</Text>
                      </VStack>
                    </Box>

                    <Box>
                      <Text fontWeight="bold" mb={2}>2. Data Usage</Text>
                      <Text mb={2}>The collected data is used exclusively for:</Text>
                      <VStack align="stretch" pl={4} spacing={1} mb={4}>
                        <Text>• Payment processing</Text>
                        <Text>• Order confirmation and delivery of products/services</Text>
                        <Text>• Customer support and communication</Text>
                        <Text>• Fulfilling legal obligations (e.g., financial reporting and audits)</Text>
                      </VStack>
                    </Box>

                    <Box>
                      <Text fontWeight="bold" mb={2}>3. Data Protection</Text>
                      <Text mb={2}>We take appropriate technical and organizational measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. This includes:</Text>
                      <VStack align="stretch" pl={4} spacing={1} mb={4}>
                        <Text>• Encryption of sensitive data (such as card information)</Text>
                        <Text>• Restricted access to personal data for authorized personnel only</Text>
                        <Text>• Regular security reviews and updates to our systems</Text>
                      </VStack>
                    </Box>

                    <Box>
                      <Text fontWeight="bold" mb={2}>4. Data Sharing</Text>
                      <Text mb={2}>We do not share your personal data with third parties except in the following cases:</Text>
                      <VStack align="stretch" pl={4} spacing={1} mb={4}>
                        <Text>• With your explicit consent</Text>
                        <Text>• When necessary for transaction processing</Text>
                        <Text>• In case of a legal request from competent authorities</Text>
                      </VStack>
                    </Box>

                    <Box>
                      <Text fontWeight="bold" mb={2}>5. Contact</Text>
                      <Text>For any questions or concerns regarding this privacy policy, please contact us at:</Text>
                      <Text pl={4}>Email: fkaerodrom2018@outlook.com</Text>
                      <Text pl={4}>Phone: +389 78 839 855</Text>
                    </Box>
                  </VStack>
                </TabPanel>

                <TabPanel>
                  <VStack align="stretch" spacing={4}>
                    <Heading size="md">Refund Policy</Heading>
                    
                    <Text mb={4}>
                      Our goal is to provide an exceptional user experience and satisfaction with every subscription or service purchase. However, we understand that there may be situations where a refund is necessary.
                    </Text>

                    <Box>
                      <Text fontWeight="bold" mb={2}>1. Refund Eligibility</Text>
                      <Text mb={2}>You may request a refund under the following circumstances:</Text>
                      <VStack align="stretch" pl={4} spacing={1} mb={4}>
                        <Text>• The service does not match the description on our website</Text>
                        <Text>• The service is faulty or non-functional upon activation</Text>
                        <Text>• The service activation is significantly delayed through no fault of your own</Text>
                        <Text>• A duplicate charge occurred due to a technical error</Text>
                      </VStack>
                    </Box>

                    <Box>
                      <Text fontWeight="bold" mb={2}>2. Refund Request Process</Text>
                      <VStack align="stretch" pl={4} spacing={1} mb={4}>
                        <Text>• Contact us within 7 days of service activation</Text>
                        <Text>• Provide your subscription details and reason for refund</Text>
                        <Text>• We will review and respond within 3 business days</Text>
                      </VStack>
                    </Box>

                    <Box>
                      <Text fontWeight="bold" mb={2}>3. Refund Method</Text>
                      <VStack align="stretch" pl={4} spacing={1} mb={4}>
                        <Text>• Refunds will be issued using your original payment method</Text>
                        <Text>• Processing time: 3-5 business days</Text>
                      </VStack>
                    </Box>

                    <Box>
                      <Text fontWeight="bold" mb={2}>4. Contact</Text>
                      <Text>For refund-related inquiries, please contact us at:</Text>
                      <Text pl={4}>Email: fkaerodrom2018@outlook.com</Text>
                      <Text pl={4}>Phone: +389 78-839-855</Text>
                    </Box>
                  </VStack>
                </TabPanel>

                <TabPanel>
                  <VStack align="stretch" spacing={4}>
                    <Heading size="md">Service Policy</Heading>
                    
                    <Text mb={4}>
                      This delivery policy outlines the terms and procedures we follow to ensure that our software services are provided on time and in excellent condition.
                    </Text>

                    <Box>
                      <Text fontWeight="bold" mb={2}>1. Service Activation</Text>
                      <Text mb={2}>We offer various service activation methods, including:</Text>
                      <VStack align="stretch" pl={4} spacing={1} mb={4}>
                        <Text>• Instant Activation upon payment confirmation</Text>
                        <Text>• Email notification of activation status</Text>
                      </VStack>
                    </Box>

                    <Box>
                      <Text fontWeight="bold" mb={2}>2. Service Access</Text>
                      <Text mb={2}>After activation you will receive:</Text>
                      <VStack align="stretch" pl={4} spacing={1} mb={4}>
                        <Text>• Login credentials via email</Text>
                        <Text>• Setup instructions</Text>
                        <Text>• Account management information</Text>
                      </VStack>
                    </Box>

                    <Box>
                      <Text fontWeight="bold" mb={2}>3. Service Support</Text>
                      <Text>For any service-related issues, contact our support team at:</Text>
                      <Text pl={4}>Email: fkaerodrom2018@outlook.com</Text>
                      <Text pl={4}>Phone: +389 78-839-855</Text>
                    </Box>
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <Divider my={6} />

            <Alert status="info" mb={4}>
              <AlertIcon />
              Please read all policies carefully before agreeing
            </Alert>

            <Button
              width="full"
              size="lg"
              colorScheme="teal"
              onClick={handleAgree}
              isLoading={isLoading}
              loadingText="Processing"
            >
              I Agree to All Policies
            </Button>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};