import { Flex, Text } from "@chakra-ui/react";
import NavBar from "../NavBar/NavBar";
import { COLOR_PALETTE } from "../../shared/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useEffect } from "react";

export const SuccessReservation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Add default value and handle missing state
  const { id, date, time, type } = location.state || {};

  // Redirect if no state
  useEffect(() => {
    if (!location.state) {
      navigate('/choose_date');
    }
  }, [location.state, navigate]);

  // Format the date to "DD/MM/YYYY"
  const formatDate = (dateString) => {
    try {
      const dateObj = new Date(dateString);
      if (isNaN(dateObj.getTime())) {
        throw new Error('Invalid date');
      }
      const day = String(dateObj.getDate()).padStart(2, "0");
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const year = dateObj.getFullYear();
      return `${day}/${month}/${year}`;
    } catch (error) {
      console.error("Date formatting error:", error);
      return "Invalid date";
    }
  };

  const formatTime = (timeString) => {
    return timeString?.slice(0, 5) || ""; // Extract "HH:MM" from "HH:MM:SS"
  };

  // Add null check for the entire component
  if (!location.state) {
    return null;
  }

  const getReservationText = () => {
    const dateText = formatDate(date);
    const timeText = formatTime(time);
    
    if (type === 'stadium') {
      return `Successful Reservation for ${dateText} at ${timeText}`;
    }
    
    return `Successful${id ? ` ${id}` : ''} Reservation for ${dateText} at ${timeText}`;
  };

  return (
    <Flex backgroundColor={COLOR_PALETTE.background} height={"100vh"}>
      <NavBar navigateTo="/choose_date" />
      <Flex direction={"column"} alignItems={"center"} marginTop={"60%"}>
        <CheckCircleIcon color={"#2E8B57"} boxSize={20} />
        <Text mt="20px" color="white" as="h3" textAlign="center">
          {getReservationText()}
        </Text>
      </Flex>
    </Flex>
  );
};