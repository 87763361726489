import React from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


export const AdminDashboard = () => {
  const navigate = useNavigate();
  const isAdmin = true;

  return (
    <Box backgroundColor="blue.700" height="100vh" padding={5}>
      <Flex direction="column" alignItems="center" justifyContent="center" height="100vh">
        <Text fontSize="2xl" color="white" fontWeight="bold" textAlign="center" width="100%" mb={6}>
          Admin Dashboard
        </Text>
        <Button mt={10} w="80vw" onClick={() => navigate("/admin/users")}>
          Users
        </Button>
        <Button mt={10} w="80vw" onClick={() => navigate("/admin/reservations")}>
          Reservations
        </Button>
        <Button mt={10} w="80vw" onClick={() => navigate("/admin/memberships")}>
          Membership Payments
        </Button>
        <Button mt={10} w="80vw" onClick={() => navigate("/", { state: { isAdmin: true } })}>
          Register Trainer
        </Button>
      </Flex>
    </Box>
  );
};
