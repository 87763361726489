import React from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const StudentDashboard = () => {
  const navigate = useNavigate();

  return (
    <Box backgroundColor="blue.700" height="100vh" padding={5}>
      <Flex direction="column" alignItems="center" justifyContent="center" height="100vh">
        <Text fontSize="2xl" color="white" fontWeight="bold" textAlign="center" width="100%" mb={6}>
          Student Dashboard
        </Text>
        <Button mt={10} w="80vw" onClick={() => navigate("/choose_date")}>
          Check Trainings
        </Button>
        <Button mt={10} w="80vw" onClick={() => navigate("/choose_membership_month")}>
          Pay Membership
        </Button>
      </Flex>
    </Box>
  );
};
