import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { COLOR_PALETTE } from "../../shared/colors";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import axios from "../../apiUtils";
import NavBar from "../NavBar/NavBar";

export const ChooseDate = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [user, setUser] = useState(null);

  const timeSlots = [
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
  ];

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get("/me/");
        setUser(response.data);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    fetch();
  }, []);

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
  };

  return (
    <Flex
      backgroundColor={COLOR_PALETTE.background}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={5}
      height="100vh"
    >
      <NavBar navigateTo="/login" />
      <Text
        fontSize="2xl"
        color="white"
        fontWeight="bold"
        textAlign="center"
        width="100%"
        mb={6}
      >
        Choose Date and Time of Reservation
      </Text>
      <Box
        mb={10}
        mt={4}
        backgroundColor="white"
        borderRadius="15px"
        padding={4}
      >
        <DayPicker mode="single" selected={selected} onSelect={setSelected} />
      </Box>
      <Flex columnGap={3} rowGap={2} wrap="wrap" justifyContent="center">
        {timeSlots.map((slot, index) => (
          <Button
            key={index}
            backgroundColor={selectedSlot === slot ? "teal" : "gray.200"}
            onClick={() => handleSlotClick(slot)}
            _hover={{
              backgroundColor: selectedSlot === slot ? "teal.600" : "gray.300",
            }}
            _active={{
              backgroundColor: selectedSlot === slot ? "teal.800" : "gray.400",
            }}
          >
            {slot}
          </Button>
        ))}
      </Flex>
      <Button
        mt={10}
        mb={10}
        w="80vw"
        isDisabled={selected === null || selectedSlot === ""}
        className="select-button"
        onClick={() => {
          if (user.role === "student") {
            navigate("/choose_trainer", {
              state: { date: selected, time: selectedSlot },
            });
          } else {
            navigate("/choose_field", {
              state: { date: selected, time: selectedSlot },
            });
          }
        }}
      >
        Select
      </Button>
    </Flex>
  );
};
