import React from "react";
import { Box, Container, VStack, Image, Heading, Text, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { COLOR_PALETTE } from "../../shared/colors";
import logo from "../../assets/img/Logo.png";

export const Contact = () => {
  const navigate = useNavigate();

  return (
    <Box 
      minHeight="100vh"
      backgroundColor={COLOR_PALETTE.background}
      py={4}
      px={4}
    >
      <Container maxW="container.sm">
        <VStack spacing={6} align="stretch">
          {/* Logo Section */}
          <Flex justify="center" mt={4} mb={6}>
            <Image 
              src={logo} 
              alt="Logo"
              maxW="200px"
              w="50%"
              objectFit="contain"
            />
          </Flex>

          {/* Title */}
          <Heading
            as="h3"
            size="lg"
            color="white"
            textAlign="center"
            mb={6}
          >
            Contact Us
          </Heading>

          {/* Contact Information */}
          <Box 
            w="100%"
            maxW="450px"
            mx="auto"
            px={4}
            backgroundColor="white"
            borderRadius="md"
            p={6}
          >
            <VStack spacing={4} align="stretch">
              <Box>
                <Text fontWeight="bold">Email:</Text>
                <Text>fkaerodrom</Text>
              </Box>

              <Box>
                <Text fontWeight="bold">Phone:</Text>
                <Text>+38978839855</Text>
              </Box>

              <Box>
                <Text fontWeight="bold">Company:</Text>
                <Text>FK AERODROM 2018 Skopje</Text>
              </Box>

              <Box>
                <Text fontWeight="bold">Address:</Text>
                <Text>BULEVAR JANE SANDANSKI br.9-3/1</Text>
                <Text>SKOPJE - AERODROM, AERODROM</Text>
              </Box>

              <Box>
                <Text fontWeight="bold">EMBS:</Text>
                <Text>7280360</Text>
              </Box>

              <Box>
                <Text fontWeight="bold">EDB:</Text>
                <Text>4032018538060</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Business Activity:</Text>
                <Text>Activities of sports clubs</Text>
              </Box>
            </VStack>
          </Box>

          {/* Navigation Links */}
          <Flex justify="center" mt={6}>
            <Text
              color="white"
              cursor="pointer"
              onClick={() => navigate("/")}
              fontSize="lg"
              mx={4}
            >
              Register
            </Text>
          </Flex>
        </VStack>
      </Container>
    </Box>
  );
};