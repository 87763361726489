import React from "react";
import {
  Flex,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
} from "@chakra-ui/react";
import { ChevronLeftIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const NavBar = ({ navigateTo }) => {
  const navigate = useNavigate();

  return (
    <Flex
      top={0}
      position={"absolute"}
      w={"100vw"}
      h="3rem"
      background={"white"}
      alignItems={"center"}
      justifyContent={"space-between"}
      px={4}
    >
      {/* Back Button */}
      <ChevronLeftIcon
        boxSize={10}
        onClick={() => navigate(navigateTo)}
        cursor="pointer" // Adding pointer to show clickable interaction
      />

      {/* Burger Menu Popover */}
      <Popover>
        <PopoverTrigger>
          <IconButton
            aria-label="Open menu"
            icon={<HamburgerIcon boxSize={8} />} // Adjust icon size for better appearance
            variant="unstyled" // Remove button styling
            _hover={{ background: "none" }} // Remove hover background
            _active={{ background: "none" }} // Remove active background
            _focus={{ boxShadow: "none" }} // Remove focus outline
          />
        </PopoverTrigger>
        <PopoverContent width="200px" boxShadow="lg" borderRadius="md">
          <PopoverBody display="flex" flexDirection="column" p={4}>
            <Button
              colorScheme="blue"
              mb={2}
              onClick={() => navigate("/future-reservations")}
            >
              Future Reservations
            </Button>
            <Button
              colorScheme="blue"
              mb={2}
              onClick={() => navigate("/past-reservations")}
            >
              Reservations History
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                localStorage.clear();
                navigate("/login");
              }}
            >
              Logout{" "}
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default NavBar;
