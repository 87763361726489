import { useEffect, useState } from "react";
import axios from "../../apiUtils";
import {
  VStack,
  Box,
  Text,
  HStack,
  Select,
  Avatar,
  Flex,
  Input,
  Button
} from "@chakra-ui/react";
import { COLOR_PALETTE } from "../../shared/colors";
import NavBar from "../NavBar/NavBar";

export const AdminReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [name, setName] = useState("");  // Filter for name
  const [field, setField] = useState(""); // Filter for field
  const [date, setDate] = useState("");  // Filter for date

  useEffect(() => {
    const fetch = async () => {
      try {
        // Send the filters as part of the request body
        const response = await axios.post("/all-reservations/", {
          name: name || null, // Send null if filter is not set
          field: field || null,
          date: date || null
        });
        setReservations(response.data);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    fetch();
  }, [name, field, date]); // Trigger request when filter changes

  const exportToCSV = async () => {
    try {
      const response = await axios.get("/reservations/export-reservations-csv/", {
        responseType: 'blob', // Important for file downloads
      });
      
      // Create a blob from the response data
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'reservations.csv'); // Download the CSV file
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Clean up after download
    } catch (error) {
      console.error("Export error:", error);
    }
  };

  const handleNameChange = (event) => setName(event.target.value);
  const handleFieldChange = (event) => setField(event.target.value);
  const handleDateChange = (event) => setDate(event.target.value);

  // Format the date to "DD/MM/YYYY"
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Format the time to "HH:MM"
  const formatTime = (timeString) => timeString.slice(0, 5); // Extract "HH:MM" from "HH:MM:SS"

  return (
    <Box backgroundColor={"blue.700"} minHeight={"100vh"} p={5}>
      <Flex justifyContent="center">
        <NavBar navigateTo="/admin" />
      </Flex>

      <Flex direction="column" align="center" w="100%" mt={10}>
        <Box textAlign="center" w="full" maxW="md" mb={10}>
          <Text fontSize="2xl" fontWeight="bold" color="white">
            Reservations
          </Text>
          <HStack spacing={4} mt={2} justifyContent="center">
            <Text color="white">Filter by:</Text>
            {/* Filter by Name */}
            <Input
              backgroundColor={"#C1CFFF"}
              placeholder="Name"
              fontSize={15}
              w="20vw"
              value={name}
              onChange={handleNameChange}
            />
            {/* Filter by Field */}
            <Select
              backgroundColor={"#C1CFFF"}
              fontSize={15}
              placeholder="Field"
              w="20vw"
              value={field}
              onChange={handleFieldChange}
            >
              <option value="1">D</option>
              <option value="2">P</option>
              <option value="3">Z</option>
              <option value="4">D</option>
              <option value="5">I</option>
              {/* Add more field options here */}
            </Select>
            {/* Filter by Date */}
            <Input
              backgroundColor={"#C1CFFF"}
              fontSize={15}
              placeholder="Date"
              w="20vw"
              type="date"
              value={date}
              onChange={handleDateChange}
            />
          </HStack>
        </Box>

        <VStack spacing={4} w="100%" align="center">
          {reservations.map((reservation, index) => (
            <Box
              key={index}
              borderWidth="1px"
              borderRadius="lg"
              p={3}
              w="full"
              maxW="md"
              backgroundColor="white"
              boxShadow="md"
              position="relative"
            >
              <HStack spacing={4}>
                <Avatar src={reservation.avatar} name={reservation.name} />
                <VStack align="flex-start" spacing={0} flex="1">
                  <Text fontWeight="bold">{reservation.user_name}</Text>
                  <Text fontWeight="bold">Field: {reservation.field_name}</Text>
                  <HStack spacing={2} alignItems="baseline">
                    <Text fontWeight="bold" fontSize="sm">
                      {formatDate(reservation.date)}
                    </Text>
                    <Text fontWeight="bold" fontSize="sm">
                      {formatTime(reservation.time)}
                    </Text>
                  </HStack>
                </VStack>
                <HStack spacing={2} ml="auto"></HStack>
              </HStack>
            </Box>
          ))}
        </VStack>
        <Box position="fixed" bottom="0" width="100%" bg="blue.800" p={3} zIndex="1000">
          <Flex justifyContent="center">
            <Button
              onClick={exportToCSV}
              colorScheme="teal"
              size="lg"
            >
              Export to CSV
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
