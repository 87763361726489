import React, { useEffect, useState } from "react";
import { Box, Flex, Text, SimpleGrid, Spinner, useToast } from "@chakra-ui/react";
import { COLOR_PALETTE } from "../../shared/colors";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../apiUtils";
import NavBar from "../NavBar/NavBar";
import { toast as hotToast } from "react-hot-toast"; 

const fields = [
  { id: "Field D", color: "blue.300" },
  { id: "Field P", color: "blue.300" },
  { id: "Field Z", color: "blue.300", span: 2 },
  { id: "Field B", color: "blue.300" },
  { id: "Field I", color: "blue.300" },
];

const fieldIdMapping = {
  "Field D": 1,
  "Field P": 2,
  "Field Z": 3,
  "Field B": 4,
  "Field I": 5,
};

export const ChooseField = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const chakraToast = useToast();
  
  const [user, setUser] = useState(null);
  const [selectedFields, setSelectedFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Check if state exists and has required properties
  useEffect(() => {
    if (!location.state || !location.state.date || !location.state.time) {
      chakraToast({
        title: "Invalid Navigation",
        description: "Please select a date and time first",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      navigate('/choose_date');
      return;
    }
  }, [location.state, navigate]);

  const { date, time } = location.state || {};

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    } catch (error) {
      console.error("Date formatting error:", error);
      return null;
    }
  };

  const selectedFieldIds = selectedFields.map((field) => `Field ${field.name}`);

  // Combined fetch for user and reserved fields with better error handling
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!date || !time) return;

      try {
        setLoading(true);
        setError(null);

        const formattedDate = formatDate(date);
        if (!formattedDate) {
          throw new Error('Invalid date format');
        }

        const [userResponse, fieldsResponse] = await Promise.all([
          axios.get("/me/"),
          axios.post("/fields/reserved_fields/", {
            date: formattedDate,
            time: `${time}:00`,
          })
        ]);

        if (isMounted) {
          setUser(userResponse.data);
          setSelectedFields(Array.isArray(fieldsResponse.data) ? fieldsResponse.data : []);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        if (isMounted) {
          setError(error.message);
          hotToast.error("Failed to load field data. Please try again.");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [date, time]);

  const reserveStadium = async () => {
    if (!user || !date || !time) {
      chakraToast({
        title: "Error",
        description: "Missing required information. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (selectedFields.length > 0) {
      chakraToast({
        title: "Error",
        description: "You cannot reserve the stadium if any fields are already reserved.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const formattedDate = formatDate(date);
      if (!formattedDate) {
        throw new Error('Invalid date format');
      }

      const response = await axios.post("/reservations/reserve-stadium/", {
        user_id: user.id,
        date: formattedDate,
        time: `${time}:00`,
      });

      if (user.role === "trainer") {
        navigate("/successreservation", {
          state: { 
            type: 'stadium',
            date: formattedDate, 
            time: `${time}:00`
          },
          replace: true
        });
      } else if (response.data.redirectUrl) {
        window.location.href = response.data.redirectUrl;
      }
    } catch (error) {
      console.error("Stadium reservation error:", error);
      chakraToast({  // Use Chakra UI toast format
        title: "Error",
        description: error.response?.data?.error || "Failed to reserve stadium",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const Field = ({ id, color, span, date, time }) => {
    const isReserved = selectedFieldIds.includes(id);
    const outlineColor = isReserved ? "#A9A9A9" : "#4262D3";

    const handleFieldClick = async () => {
      if (!user || !date || !time) {
        hotToast.error("Missing required information. Please try again.");
        return;
      }
    
      if (isReserved) return;
    
      const confirmed = window.confirm(`Continue with ${id}?`);
      if (!confirmed) return;
    
      try {
        const formattedDate = formatDate(date);
        if (!formattedDate) {
          throw new Error('Invalid date format');
        }
    
        const fieldId = fieldIdMapping[id];  // Get the numeric field ID
        const response = await axios.post("/reservations/make/", {  // Use the single field endpoint
          user_id: user.id,
          field_id: fieldId,  // Pass the field_id
          date: formattedDate,
          time: `${time}:00`,
        });
    
        if (user.role === "trainer") {
          navigate("/successreservation", {
            state: { 
              id: id,  // Pass the field name
              date: formattedDate, 
              time: `${time}:00`,
              type: 'field'  // Specify this is a field reservation
            },
            replace: true
          });
        } else if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
        }
      } catch (error) {
        console.error("Field reservation error:", error);
        hotToast.error(error.response?.data?.error || "Failed to reserve field");
      }
    };

    return (
      <Box
        bg={COLOR_PALETTE.background}
        height={id === "Field Z" ? "150px" : "200px"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        border={`2px solid ${outlineColor}`}
        position="relative"
        gridColumn={span ? `span ${span}` : undefined}
        onClick={() => !isReserved && !loading && handleFieldClick()}
        cursor={isReserved || loading ? "not-allowed" : "pointer"}
        style={{ touchAction: "manipulation" }}
        transition="transform 0.2s"
        _hover={!isReserved && !loading ? { transform: "scale(1.02)" } : {}}
      >
        {/* Rest of the Field component remains the same */}
        <Text 
        fontSize="xl" 
        color={isReserved ? "#f25a5a" : "white"}  // Maroon color when reserved
        fontWeight="bold" 
        zIndex={2}
        >
        {isReserved ? "Reserved" : id}
        </Text>
        {/* Field Design */}
        <Box
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          zIndex={1}
        >
          {/* Outer border */}
          <Box
            borderTop={`2px solid ${outlineColor}`}
            position="absolute"
            top="0"
            left="0"
            right="0"
          />
          <Box
            borderBottom={`2px solid ${outlineColor}`}
            position="absolute"
            bottom="0"
            left="0"
            right="0"
          />
          <Box
            borderLeft={`2px solid ${outlineColor}`}
            position="absolute"
            top="0"
            bottom="0"
            left="0"
          />
          <Box
            borderRight={`2px solid ${outlineColor}`}
            position="absolute"
            top="0"
            bottom="0"
            right="0"
          />
          {/* Center circle */}
          <Box
            border={`2px solid ${outlineColor}`}
            borderRadius="50%"
            width="50px"
            height="50px"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          />
          {/* Midfield line */}
          <Box
            borderTop={`2px solid ${outlineColor}`}
            width="100%"
            height="50px"
            position="absolute"
            top="50%"
          />
          {/* Penalty box top */}
          <Box
            borderTop={`2px solid ${outlineColor}`}
            width="30%"
            position="absolute"
            top="10%"
            left="35%"
          />
          {/* Penalty box bottom */}
          <Box
            borderBottom={`2px solid ${outlineColor}`}
            width="30%"
            position="absolute"
            bottom="10%"
            left="35%"
          />
        </Box>
      </Box>
    );
  };

  if (error) {
    return (
      <Flex
        backgroundColor={COLOR_PALETTE.background}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        p={4}
      >
        <Text color="white" textAlign="center" mb={4}>
          Error loading fields. Please try again.
        </Text>
        <Box
          as="button"
          bg="blue.500"
          color="white"
          px={6}
          py={3}
          borderRadius="md"
          onClick={() => navigate('/choose_date')}
          _hover={{ bg: "blue.600" }}
        >
          Go Back
        </Box>
      </Flex>
    );
  }

  return (
    <Flex
      backgroundColor={COLOR_PALETTE.background}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={5}
      minHeight="100vh"
    >
      <NavBar navigateTo="/choose_date" />
      <Text
        fontSize="2xl"
        color="white"
        fontWeight="bold"
        textAlign="center"
        width="100%"
        mb={6}
      >
        Choose Date and Time of Reservation
      </Text>
      {loading ? (
        <Spinner color="white" size="xl" />
      ) : (
        <>
          <SimpleGrid columns={2} spacing={10} width="80%">
            {fields.map((field) => (
              <Field
                key={field.id}
                id={field.id}
                color={field.color}
                span={field.span}
                date={date}
                time={time}
              />
            ))}
          </SimpleGrid>
          <Box 
            mt={10} 
            bg="blue.500" 
            p={5} 
            borderRadius="md" 
            onClick={reserveStadium}
            cursor={loading ? "not-allowed" : "pointer"}
            transition="transform 0.2s"
            _hover={{ transform: "scale(1.02)" }}
          >
            <Text fontSize="lg" color="white" fontWeight="bold">
              Reserve the whole stadium
            </Text>
          </Box>
        </>
      )}
    </Flex>
  );
};