import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from "./pages/Login/Login";
import { ChooseField } from "./pages/ChooseField/ChooseField";
import { ChooseDate } from "./pages/ChooseDate/ChooseDate";
import { AuthProvider } from "./CustomRoutes/AuthContext";
import { PrivateRoute } from "./CustomRoutes/PrivateRoute";
import { Register } from "./pages/Register/Register";
import { Contact } from "./pages/Contact/Contact";
import { PolicyConsent } from "./pages/PolicyConsent/PolicyConsent";
import { Success } from "./pages/Success/Success";
import { ErrorPayment } from "./pages/ErrorPayment/ErrorPayment";
import { SuccessMembership } from "./pages/SuccessMembership/SuccessMembership";
import { AdminReservations } from "./pages/AdminReservations/AdminReservations";
import { FutureReservations } from "./pages/FutureReservations/FutureReservations";
import { PastReservations } from "./pages/PastReservations/PastReservations";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { PasswordResetConfirm } from "./pages/PasswordResetConfirm/PasswordResetConfirm";
import { SuccessReservation } from "./pages/SuccessReservation/SuccessReservation";
import { ChooseTrainer } from "./pages/ChooseTrainer/ChooseTrainer";
import { AdminDashboard } from "./pages/AdminDashboard/AdminDashboard";
import { AdminUsers } from "./pages/AdminUsers/AdminUsers";
import { StudentDashboard } from "./pages/StudentDashboard/StudentDashboard";
import { ChooseMembershipMonth } from "./pages/ChooseMembershipMonth/ChooseMembershipMonth";
import { AdminMemberships } from "./pages/AdminMemberships/AdminMemberships";

const App = () => {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/policy-consent" element={<PolicyConsent />} />
            <Route
              path="/future-reservations"
              element={<FutureReservations />}
            />
            <Route path="/past-reservations" element={<PastReservations />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/student-dashboard" element={<StudentDashboard />} />

            <Route
              path="/password-reset-confirm/:uidb64/:token"
              element={<PasswordResetConfirm />}
            />
            <Route path="/admin/reservations" element={<AdminReservations />} />
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <AdminDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/reservations"
              element={
                <PrivateRoute>
                  <AdminReservations />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <PrivateRoute>
                  <AdminUsers />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/memberships"
              element={
                <PrivateRoute>
                  <AdminMemberships />
                </PrivateRoute>
              }
            />
            <Route
              path="/choose_date"
              element={
                <PrivateRoute>
                  <ChooseDate />
                </PrivateRoute>
              }
            />
            <Route
              path="/choose_field"
              element={
                <PrivateRoute>
                  <ChooseField />
                </PrivateRoute>
              }
            />
            <Route
              path="/choose_trainer"
              element={
                <PrivateRoute>
                  <ChooseTrainer />
                </PrivateRoute>
              }
            />
            <Route
              path="/choose_membership_month"
              element={
                <PrivateRoute>
                  <ChooseMembershipMonth />
                </PrivateRoute>
              }
            />
            <Route
              path="/successreservation"
              element={
                <PrivateRoute>
                  <SuccessReservation />
                </PrivateRoute>
              }
            />
            <Route
              path="/successpayment"
              element={
                <PrivateRoute>
                  <Success />
                </PrivateRoute>
              }
            />
            <Route
              path="/errorpayment"
              element={
                <PrivateRoute>
                  <ErrorPayment />
                </PrivateRoute>
              }
            />
            <Route
              path="/successmembershippayment"
              element={
                <PrivateRoute>
                  <SuccessMembership />
                </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
};

export default App;
