import { useEffect, useState } from "react";
import { Flex, Text, Box, VStack, Spinner } from "@chakra-ui/react";
import axios from "../../apiUtils";
import { useNavigate } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import { COLOR_PALETTE } from "../../shared/colors";

export const PastReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const responseUser = await axios.get("/me/");
        const response = await axios.post("/reservations/user-reservations/", {
          user_id: responseUser.data.id,
        });
        setReservations(response.data.past_reservations);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching past reservations:", error);
        setLoading(false);
      }
    };

    fetchReservations();
  }, []);

  const formatDate = (dateString, timeString) => {
    const date = new Date(`${dateString}T${timeString}`);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  if (loading) {
    return <Spinner color="blue.500" />;
  }

  return (
    <Flex
      backgroundColor={COLOR_PALETTE.background}
      flexDirection="column"
      alignItems={"center"}
      justifyContent="flex-start"
      p={5}
      minHeight="100vh"
      overflowY="auto"
    >
      <NavBar navigateTo="/choose_date" />
      <Text fontSize="2xl" color={"white"} mt={10} mb={4} fontWeight="bold">
        Reservation History
      </Text>
      <VStack spacing={4} width="80%">
        {reservations.length > 0 ? (
          reservations.map((reservation) => (
            <Box
              key={reservation.id}
              backgroundColor="white"
              p={4}
              borderRadius="md"
              width="100%"
              textAlign="center"
              fontWeight="bold"
            >
              <Text>{formatDate(reservation.date, reservation.time)}</Text>
              <Text>Field {reservation.field_name}</Text>
            </Box>
          ))
        ) : (
          <Text color="white">No past reservations found.</Text>
        )}
      </VStack>
    </Flex>
  );
};
