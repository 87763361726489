import { CheckCircleIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { COLOR_PALETTE } from "../../shared/colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import NavBar from "../NavBar/NavBar";

export const SuccessMembership = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Use searchParams to extract month_year
  const monthYear = searchParams.get("month_year"); // Fixed variable name

  // Format the month_year to "Month Year" (e.g., "February 2024")
  const formatMonthYear = (monthYearString) => {
    const [year, month] = monthYearString.split("-");
    const date = new Date(year, month - 1);
    const formattedMonth = date.toLocaleString("default", { month: "long" });
    return `${formattedMonth} ${year}`;
  };

  return (
    <Flex backgroundColor={COLOR_PALETTE.background} height={"100vh"}>
      <NavBar navigateTo="/choose_membership_month" />
      <Flex direction={"column"} alignItems={"center"} marginTop={"60%"}>
        <CheckCircleIcon color={"#2E8B57"} boxSize={20} />
        <Text mt="20px" color="white" as="h3" textAlign="center">
          {`Your membership payment for ${formatMonthYear(monthYear)} was successful!`}
        </Text>
      </Flex>
    </Flex>
  );
};
