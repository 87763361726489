import React from "react";
import { useLocation } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import { COLOR_PALETTE } from "../../shared/colors";
import logo from "../../assets/img/Logo.png";
import config from "../../config.json";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Flex,
  Text,
  Input,
  Container,
  VStack,
  Image,
  Heading,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import axios from "../../apiUtils";
import Select from "react-select";

// Updated validation schema
const schema = yup.object().shape({
  role: yup.string().required("Role is required"),
  name: yup
    .string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters long"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required")
    .min(6, "Email must be at least 6 characters long"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long"),
  confirm_password: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  biography: yup.string().when("role", {
    is: "trainer",
    then: () => yup.string().required("Biography is required"),
  }),
  generation: yup.number().when("role", {
    is: "student",
    then: () =>
      yup
        .number()
        .required("Generation is required")
        .min(1900, "Invalid year")
        .max(new Date().getFullYear(), "Invalid year"),
  }),
  phone: yup.string().when("role", {
    is: "student",
    then: () =>
      yup
        .string()
        .required("Phone number is required")
        .matches(/^\d{9}$/, "Phone number must be exactly 9 digits"),
  }),
});

export const Register = () => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = location.state?.isAdmin || false;
  const role = watch("role");

  // Function to get full name placeholder based on role
  const getFullNamePlaceholder = (role) => {
    switch (role) {
      case "student":
        return "Student Name and Surname";
      case "trainer":
        return "Trainer Name and Surname";
      default:
        return "Full Name";
    }
  };

  const onSubmit = async (data) => {
    const { confirm_password, ...rest } = data;

    let roleEndpoint = "";
    switch (data.role) {
      case "recreational":
        roleEndpoint = "register/recreational/";
        break;
      case "trainer":
        if (!isAdmin) {
          alert("You are not authorized to register trainers.");
          return;
        }
        roleEndpoint = "register/trainer/";
        break;
      case "student":
        roleEndpoint = "register/student/";
        break;
      default:
        console.error("Invalid role selected");
        return;
    }

    const API = `${config.baseURL}/${roleEndpoint}`;

    try {
      await axios.post(API, { ...rest });
      navigate("/policy-consent");
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  const years = Array.from({ length: 2019 - 2004 + 1 }, (_, i) => ({
    value: 2004 + i,
    label: (2004 + i).toString(),
  }));

  // Custom styles for react-select to match the theme
  const selectStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "white",
      marginBottom: "1rem",
    }),
  };

  return (
    <Box
      minHeight="100vh"
      backgroundColor={COLOR_PALETTE.background}
      py={4}
      px={4}
    >
      <Container maxW="container.sm">
        <VStack spacing={6} align="stretch">
          {/* Logo Section */}
          <Flex justify="center" mt={4} mb={6}>
            <Image src={logo} alt="Logo" maxW="200px" w="50%" objectFit="contain" />
          </Flex>

          {/* Title */}
          <Heading as="h3" size="lg" color="white" textAlign="center" mb={6}>
            Sign up
          </Heading>

          {/* Form Section */}
          <Box w="100%" maxW="450px" mx="auto" px={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing={4} align="stretch">
                {/* Role Selection */}
                <FormControl isInvalid={errors.role}>
                  <Select
                    styles={selectStyles}
                    id="role"
                    placeholder="Select role"
                    options={[
                      { value: "recreational", label: "Recreational" },
                      { value: "trainer", label: "Trainer" },
                      { value: "student", label: "Student" },
                    ]}
                    onChange={(option) => setValue("role", option.value)}
                  />
                  <FormErrorMessage>
                    {errors.role && errors.role.message}
                  </FormErrorMessage>
                </FormControl>

                {/* Full Name Input */}
                <FormControl isInvalid={errors.name}>
                  <Input
                    backgroundColor="white"
                    id="name"
                    placeholder={getFullNamePlaceholder(role)}
                    size="lg"
                    {...register("name")}
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>

                {/* Email Input */}
                <FormControl isInvalid={errors.email}>
                  <Input
                    backgroundColor="white"
                    id="email"
                    placeholder="Email"
                    size="lg"
                    {...register("email")}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>

                {/* Password Input */}
                <FormControl isInvalid={errors.password}>
                  <Input
                    backgroundColor="white"
                    id="password"
                    type="password"
                    placeholder="Password"
                    size="lg"
                    {...register("password")}
                  />
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </FormControl>

                {/* Confirm Password Input */}
                <FormControl isInvalid={errors.confirm_password}>
                  <Input
                    backgroundColor="white"
                    id="confirm_password"
                    type="password"
                    placeholder="Confirm Password"
                    size="lg"
                    {...register("confirm_password")}
                  />
                  <FormErrorMessage>
                    {errors.confirm_password && errors.confirm_password.message}
                  </FormErrorMessage>
                </FormControl>

                {/* Conditional Fields */}
                {role === "trainer" && (
                  <FormControl isInvalid={errors.biography}>
                    <Input
                      backgroundColor="white"
                      id="biography"
                      placeholder="Biography"
                      size="lg"
                      {...register("biography")}
                    />
                    <FormErrorMessage>
                      {errors.biography && errors.biography.message}
                    </FormErrorMessage>
                  </FormControl>
                )}

                {role === "student" && (
                  <>
                    <FormControl isInvalid={errors.generation}>
                      <Select
                        styles={selectStyles}
                        options={years}
                        placeholder="Select Generation"
                        onChange={(selectedOption) =>
                          setValue("generation", selectedOption.value)
                        }
                      />
                      <FormErrorMessage>
                        {errors.generation && errors.generation.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.phone}>
                      <Input
                        backgroundColor="white"
                        id="phone"
                        placeholder="Phone Number"
                        size="lg"
                        {...register("phone")}
                      />
                      <FormErrorMessage>
                        {errors.phone && errors.phone.message}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                )}

                {/* Submit Button */}
                <Button
                  width="full"
                  size="lg"
                  colorScheme="teal"
                  type="submit"
                  mt={4}
                >
                  Register
                </Button>
              </VStack>
            </form>

            {/* Login Link */}
            <Flex justify="center" mt={6} mb={8}>
              <Text
                color="white"
                cursor="pointer"
                onClick={() => navigate("/login")}
                fontSize="lg"
              >
                Login
              </Text>
            </Flex>

            <Flex justify="space-between" align="center" mt={6} mb={4}>
              <Text
                color="white"
                onClick={() => navigate("/contact")}
                fontSize="md"
                cursor="pointer"
              >
                Contact & Info
              </Text>
              <Text
                color="white"
                onClick={() => navigate("/policy-consent")}
                fontSize="md"
                cursor="pointer"
              >
                Policies
              </Text>
            </Flex>

            <Flex justify="center" gap={4} mt={2} mb={8}>
              <Image
                src="/images/visa_secure.png"
                alt="Visa Secure"
                height="40px"
                objectFit="contain"
              />
              <Image
                src="/images/mastercard.png"
                alt="Mastercard ID Check"
                height="40px"
                objectFit="contain"
              />
            </Flex>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};