import { useEffect, useState } from "react";
import axios from "../../apiUtils";
import {
  VStack,
  Box,
  Text,
  HStack,
  Select,
  Button,
  Avatar,
  Input,
  Flex,
} from "@chakra-ui/react";
import { COLOR_PALETTE } from "../../shared/colors";
import NavBar from "../NavBar/NavBar";

export const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: '',
    year: ''
  });

  // Fetch users based on the selected filters
  const fetchUsers = async () => {
    try {
      const response = await axios.post("/all-users/", {
        name: filters.name,
        role: filters.role,
        year: filters.year,
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    fetchUsers(); // Fetch when the component loads
  }, []);

  // Handle filter change and re-fetch users
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Re-fetch users when any filter is changed
  useEffect(() => {
    fetchUsers();
  }, [filters]);

  const deleteUser = async (userId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (!confirmDelete) return;

    try {
      await axios.delete('/users/delete-user/', {
        data: { user_id: userId }
      });

      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
    } catch (error) {
      console.error("Delete error:", error);
      alert("Failed to delete user.");
    }
  };

  return (
    <Box backgroundColor={"blue.700"} minHeight={"100vh"} p={5}>
      <Flex justifyContent="center">
        <NavBar navigateTo="/admin" />
      </Flex>

      <Flex direction="column" align="center" w="100%" mt={10}>
        <Box textAlign="center" w="full" maxW="md" mb={10}>
          <Text fontSize="2xl" fontWeight="bold" color="white">
            Users
          </Text>
          <HStack spacing={4} mt={2} justifyContent="center">
            <Text color="white">Filter by:</Text>
            <Input
              name="name"
              backgroundColor={"#C1CFFF"}
              placeholder="Name"
              fontSize={15}
              w="20vw"
              onChange={handleFilterChange}
              autoComplete="off"
            />
            <Select
              name="role"
              backgroundColor={"#C1CFFF"}
              fontSize={15}
              placeholder="Role"
              w="20vw"
              onChange={handleFilterChange}
            >
              <option value="recreational">Recreational</option>
              <option value="trainer">Trainer</option>
              <option value="student">Student</option>
            </Select>
            <Select
              name="year"
              backgroundColor={"#C1CFFF"}
              fontSize={15}
              placeholder="Year"
              w="20vw"
              onChange={handleFilterChange}
            >
            </Select>
          </HStack>
        </Box>

        <VStack spacing={4} w="100%" align="center">
          {users.map((user, index) => (
            <Box
              key={index}
              borderWidth="1px"
              borderRadius="lg"
              p={3}
              w="full"
              maxW="md"
              backgroundColor="white"
              boxShadow="md"
              position="relative"
            >
              <HStack spacing={4}>
                <Avatar src={user.avatar} name={user.name} />
                <VStack align="flex-start" spacing={0} flex="1">
                  <Text fontWeight="bold">{user.first_name}</Text>
                  <Text fontWeight="bold">Role: {user.role}</Text>
                  <Text fontWeight="bold">{user.email}</Text>
                </VStack>
                <HStack spacing={2} ml="auto">
                  <Button
                    colorScheme="red"
                    onClick={() => deleteUser(user.id)}
                    position="absolute"
                    right="10px"
                    top="50%"
                    transform="translateY(-50%)"
                  >
                    Delete
                  </Button>
                </HStack>
              </HStack>
            </Box>
          ))}
        </VStack>
      </Flex>
    </Box>
  );
};
