import { WarningIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { COLOR_PALETTE } from "../../shared/colors";
import NavBar from "../NavBar/NavBar";

export const ErrorPayment = () => {
  return (
    <Box backgroundColor={COLOR_PALETTE.background} p={5} height={"100vh"}>
      <Flex justifyContent="center">
        <NavBar navigateTo="/choose_date" />
      </Flex>
      <Flex direction={"column"} alignItems={"center"} marginTop={"60%"}>
        <WarningIcon color={"#B22222"} boxSize={20} />
        <Text mt="20px" color="white" as="h3" textAlign="center">
          Payment error. Please try again
        </Text>
      </Flex>
    </Box>
  );
};
