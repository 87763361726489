import axios from "../../apiUtils";
import React, { useState } from "react";
import styled from "styled-components";
import "rsuite/dist/rsuite.min.css";
import { COLOR_PALETTE } from "../../shared/colors";
import {
  Box,
  Button,
  FormControl,
  Input,
  FormErrorMessage,
  Text, 
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams, Link } from "react-router-dom"; // Import Link

const PasswordResetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically */
  height: 100vh; /* Full height of the viewport */
  background-color: ${COLOR_PALETTE.background};
`;

const schema = yup.object().shape({
  newPassword: yup.string().required("New Password is required").min(8, "Password must be at least 8 characters long"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Please confirm your password'),
});

export const PasswordResetConfirm = () => {
  const { uidb64, token } = useParams(); // Extract the uidb64 and token from the URL
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `/users/password-reset-confirm/${uidb64}/${token}/`, // Removed hard-coded base URL, as the custom axios may already have a base URL set
        { new_password: data.newPassword },
      );
      setResponseMessage(response.data.message || "Password has been reset successfully.");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setResponseMessage(error.response.data.error);
      } else {
        setResponseMessage("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PasswordResetContainer>
      <Text fontSize="2xl" color="white" mb={4}>
        Reset Your Password
      </Text>
      <Box w={"70vw"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.newPassword} mb={4}>
            <Input
              backgroundColor={"white"}
              id="newPassword"
              type="password"
              placeholder="New Password"
              {...register("newPassword")}
            />
            <FormErrorMessage>
              {errors.newPassword && errors.newPassword.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.confirmPassword} mb={4}>
            <Input
              backgroundColor={"white"}
              id="confirmPassword"
              type="password"
              placeholder="Confirm New Password"
              {...register("confirmPassword")}
            />
            <FormErrorMessage>
              {errors.confirmPassword && errors.confirmPassword.message}
            </FormErrorMessage>
          </FormControl>

          <Button width="full" mt={4} colorScheme="teal" type="submit" disabled={loading}>
            Submit
          </Button>
        </form>
      </Box>

      {loading && <Spinner color="white" mt={4} />}

      {responseMessage && !loading && (
        <>
          <Text color="white" mt={4}>
            {responseMessage}
          </Text>
          <Link to="/login">
            <Text color="teal.200" mt={2} textDecoration="underline">
              Back to Login
            </Text>
          </Link>
        </>
      )}
    </PasswordResetContainer>
  );
};