import { Flex, Avatar, Text, Box, Spinner, Button } from "@chakra-ui/react";
import { COLOR_PALETTE } from "../../shared/colors";
import NavBar from "../NavBar/NavBar";
import { useState, useEffect } from "react";
import axios from "../../apiUtils";
import { useLocation } from "react-router-dom";

const TrainingCard = ({ name, description, avatar }) => (
  <Flex
    bg="white"
    borderRadius="md"
    boxShadow="md"
    p={3}
    mb={4}
    alignItems="center"
    justifyContent="space-between"
  >
    <Flex align="center">
      <Avatar size="md" name={name} src={avatar} />
      <Box ml={3}>
        <Text fontWeight="bold">{name}</Text>
        <Text fontSize="sm" color="gray.500">
          {description}
        </Text>
      </Box>
    </Flex>
  </Flex>
);

export const ChooseTrainer = () => {
  const [trainers, setTrainers] = useState([]);
  const [loading, setLoading] = useState(true);

  const { state } = useLocation();
  const { date, time } = state;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed in JS
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const fetchTrainers = async () => {
      try {
        const response = await axios.post(
          "/users/trainers-for-student-datetime/",
          { date: formatDate(date), time: time }
        );
        setTrainers(response.data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTrainers();
  }, [date, time]);

  return (
    <Flex
      backgroundColor={COLOR_PALETTE.background}
      flexDirection="column"
      alignItems={"center"}
      justifyContent="center"
      p={5}
      height={"100vh"}
    >
      <NavBar navigateTo="/choose_date" />
      <Box>
        <Text
          fontSize="2xl"
          color="white"
          fontWeight="bold"
          textAlign="center"
          width="100%"
          mb={6}
        >
          Available Trainers for this Date and Time
        </Text>
        {loading ? (
          <Spinner color="white" />
        ) : (
          <>
            <Box
              overflowX="scroll"
              p={4}
              borderRadius="md"
              width={"100%"}
              height={"500px"}
            >
              {trainers.map((trainer, index) => (
                <TrainingCard
                  key={index}
                  name={trainer.first_name}
                  description={trainer.description}
                  avatar="https://example.com/trainer-avatar.jpg" // You can update this with actual avatars if available
                />
              ))}
            </Box>
          </>
        )}
      </Box>
    </Flex>
  );
};
