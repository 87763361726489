import React, { useContext, useState } from "react";
import "rsuite/dist/rsuite.min.css";
import { COLOR_PALETTE } from "../../shared/colors";
import logo from "../../assets/img/Logo.png";
import { AuthContext } from "../../CustomRoutes/AuthContext";
import {
  Box,
  Flex,
  Button,
  Text,
  FormControl,
  Input,
  FormErrorMessage,
  Spinner,
  Container,
  VStack,
  Image,
  Heading,
  Link,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required")
    .min(6, "Email must be at least 6 characters long"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long"),
});

export const Login = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await login(data.email, data.password);
      const isAdmin = response.data.admin;
      const userRole = response.data.userRole;

      if (isAdmin) {
        navigate("/admin");
      } else if (userRole === "student") {
        navigate("/student-dashboard");
      } else {
        navigate("/choose_date");
      }
    } catch (error) {
      setLoginError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box 
      minHeight="100vh"
      backgroundColor={COLOR_PALETTE.background}
      py={4}
      px={4}
    >
      <Container maxW="container.sm">
        <VStack spacing={6} align="stretch">
          {/* Logo Section */}
          <Flex justify="center" mt={4} mb={6}>
            <Image 
              src={logo} 
              alt="Logo"
              maxW="200px"
              w="50%"
              objectFit="contain"
            />
          </Flex>

          {/* Title */}
          <Heading
            as="h3"
            size="lg"
            color="white"
            textAlign="center"
            mb={6}
          >
            Sign in to your account
          </Heading>

          {/* Form Section */}
          <Box 
            w="100%"
            maxW="450px"
            mx="auto"
            px={4}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing={4} align="stretch">
                {/* Email Input */}
                <FormControl isInvalid={errors.email}>
                  <Input
                    backgroundColor="white"
                    id="email"
                    placeholder="Email"
                    size="lg"
                    {...register("email")}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>

                {/* Password Input */}
                <FormControl isInvalid={errors.password}>
                  <Input
                    backgroundColor="white"
                    id="password"
                    type="password"
                    placeholder="Password"
                    size="lg"
                    {...register("password")}
                  />
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </FormControl>

                {/* Error Message */}
                {loginError && (
                  <Text color="red.500" textAlign="center">
                    {loginError}
                  </Text>
                )}

                {/* Login Button or Spinner */}
                {loading ? (
                  <Flex justify="center" py={4}>
                    <Spinner color="white" size="lg" />
                  </Flex>
                ) : (
                  <Button
                    width="full"
                    size="lg"
                    colorScheme="teal"
                    type="submit"
                    isDisabled={loading}
                    mt={2}
                  >
                    Login
                  </Button>
                )}
              </VStack>
            </form>

            {/* Forgot Password Link */}
            <Flex justify="flex-end" mt={4}>
              <Text
                color="white"
                cursor="pointer"
                onClick={() => navigate("/forgot-password")}
                fontSize="sm"
                _hover={{ textDecoration: "underline" }}
              >
                Forgot Password?
              </Text>
            </Flex>

            {/* Bottom Section */}
            <VStack spacing={2} mt={{ base: 8, md: 12 }} mb={4}>
              <Text
                onClick={() => navigate("/")}
                color="white"
                fontSize={{ base: "md", md: "lg" }}
                cursor="pointer"
                _hover={{ textDecoration: "underline" }}
              >
                Don't have an account?
              </Text>
            </VStack>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};