import { useEffect, useState } from "react";
import axios from "../../apiUtils";
import {
  VStack,
  Box,
  Text,
  HStack,
  Select,
  Avatar,
  Flex,
  Input,
  Button
} from "@chakra-ui/react";
import { COLOR_PALETTE } from "../../shared/colors";
import NavBar from "../NavBar/NavBar";

export const AdminMemberships = () => {
  const [memberships, setMemberships] = useState([]);
  const [name, setName] = useState("");  // Filter for name
  const [month, setMonth] = useState(""); // Filter for month
  const [year, setYear] = useState("");  // Filter for year
  const [yearOfBirth, setYearOfBirth] = useState("");

  useEffect(() => {
    const fetch = async () => {
      try {
        // Send the filters as part of the request body
        const response = await axios.post("/payments/all-memberships/", {
          name: name || null, // Send null if filter is not set
          month: month || null,
          year: year || null,
          year_of_birth: yearOfBirth || null
        });
        setMemberships(response.data);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    fetch();
  }, [name, month, year, yearOfBirth]); // Trigger request when filter changes

  const handleNameChange = (event) => setName(event.target.value);
  const handleMonthChange = (event) => setMonth(event.target.value);
  const handleYearChange = (event) => setYear(event.target.value);
  const handleYearOfBirthChange = (event) => setYearOfBirth(event.target.value);

  const exportToCSV = async () => {
    try {
      const response = await axios.get("/payments/export-memberships-csv/");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "memberships.csv");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error exporting CSV:", error);
    }
  };

  const formatMonthYear = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <Box backgroundColor={"blue.700"} minHeight={"100vh"} p={5}>
      <Flex justifyContent="center">
        <NavBar navigateTo="/admin" />
      </Flex>

      <Flex direction="column" align="center" w="100%" mt={10}>
        <Box textAlign="center" w="full" maxW="md" mb={10}>
          <Text fontSize="2xl" fontWeight="bold" color="white">
            Memberships
          </Text>
          <HStack spacing={4} mt={2} justifyContent="center">
            <Text color="white">Filter by:</Text>
            {/* Filter by Name */}
            <Input
              backgroundColor={"#C1CFFF"}
              placeholder="Name"
              fontSize={15}
              w="20vw"
              value={name}
              onChange={handleNameChange}
            />
            {/* Filter by Month */}
            <Select
              backgroundColor={"#C1CFFF"}
              fontSize={15}
              placeholder="Month"
              w="20vw"
              value={month}
              onChange={handleMonthChange}
            >
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </Select>
            {/* Filter by Year */}
            <Input
              backgroundColor={"#C1CFFF"}
              fontSize={15}
              placeholder="Year"
              w="20vw"
              type="number"
              value={year}
              onChange={handleYearChange}
            />
            {/* Filter by Year of Birth */}
            <Input
              backgroundColor={"#C1CFFF"}
              fontSize={15}
              placeholder="Generation"
              w="15vw"
              value={yearOfBirth}
              onChange={handleYearOfBirthChange}
            />
          </HStack>
        </Box>

        <VStack spacing={4} w="100%" align="center">
          {memberships.map((membership, index) => (
            <Box
              key={index}
              borderWidth="1px"
              borderRadius="lg"
              p={3}
              w="full"
              maxW="md"
              backgroundColor="white"
              boxShadow="md"
              position="relative"
            >
              <HStack spacing={4}>
                <Avatar src={membership.avatar} name={membership.name} />
                <VStack align="flex-start" spacing={0} flex="1">
                  <Text fontWeight="bold">{membership.user_name}</Text>
                  <Text>{formatMonthYear(membership.membership_month_year)}</Text>
                  <Text fontWeight="bold">Generation: {membership.user_age_group}</Text> {/* Display Age Group */}
                </VStack>
                <HStack spacing={2} ml="auto"></HStack>
              </HStack>
            </Box>
          ))}
        </VStack>
        <Box position="fixed" bottom="0" width="100%" bg="blue.800" p={3} zIndex="1000">
          <Flex justifyContent="center">
            <Button
              onClick={exportToCSV}
              colorScheme="teal"
              size="lg"
            >
              Export to CSV
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
