import axios from "axios";
import config from './config.json';

const instance = axios.create({ baseURL: config.baseURL });

// Request interceptor to add the access token to request headers
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token refreshing
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Attempt to refresh the token
        const response = await axios.post(
          `${config.baseURL}/refresh/`,
          {
            refresh: localStorage.getItem("refresh_token"),
          }
        );

        const { access } = response.data;
        localStorage.setItem("token", access);

        // Update Axios instance headers
        instance.defaults.headers.common["Authorization"] = `Bearer ${access}`;

        // Retry the original request
        return instance(originalRequest);
      } catch (refreshError) {
        // Handle refresh errors (e.g., redirect to login)
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
