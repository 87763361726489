import { CheckCircleIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { COLOR_PALETTE } from "../../shared/colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import NavBar from "../NavBar/NavBar";

export const Success = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Use searchParams to extract field, time, and date
  const field = searchParams.get("field"); // Fixed variable name
  const time = searchParams.get("time");
  const date = searchParams.get("date");

  // Format the date to "DD/MM/YYYY"
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Format the time to "HH:MM"
  const formatTime = (timeString) => {
    return timeString.slice(0, 5); // Extract "HH:MM" from "HH:MM:SS"
  };

  return (
    <Flex backgroundColor={COLOR_PALETTE.background} height={"100vh"}>
      <NavBar navigateTo="/choose_date" />
      <Flex direction={"column"} alignItems={"center"} marginTop={"60%"}>
        <CheckCircleIcon color={"#2E8B57"} boxSize={20} />
        <Text mt="20px" color="white" as="h3" textAlign="center">
          {`Reservation successful ${formatDate(date)} at ${formatTime(time)}`}
        </Text>
      </Flex>
    </Flex>
  );
};
