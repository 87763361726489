import React, { useState } from "react";
import { Box, Button, Flex, Text, FormControl, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import axios from "../../apiUtils";
import toast from "react-hot-toast";

export const ChooseMembershipMonth = () => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null); // For year selection
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  // Generate the years dynamically
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, i) => ({
    value: (currentYear - i).toString(),
    label: (currentYear - i).toString(),
  }));

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
    setError("");
  };

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    setError("");
  };

  const handleSubmit = async () => {
    if (!selectedMonth || !selectedYear) {
      setError("Please select both a month and a year.");
      return;
    }

    setIsSubmitting(true);

    try {
      const responseUser = await axios.get("/me/");
      const monthYear = `${selectedYear.value}-${selectedMonth.value}`; // Format as "YYYY-MM"
      const response = await axios.post("/payments/pay-membership/", {
        user_id: responseUser.data.id,
        month_year: monthYear, // Pass month and year in the request
      });

      const redirectUrl = response.data.redirectUrl;
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        console.error("No redirect URL received.");
        toast.error("Payment processing failed.");
      }
    } catch (error) {
      console.error("Error processing membership payment:", error);
      toast.error(error.response?.data?.error || "Payment error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Flex backgroundColor="blue.700" height="100vh" alignItems="center" justifyContent="center" padding={5}>
      <Box backgroundColor="white" padding={6} borderRadius="md" width="80vw" maxW="400px">
        <Text fontSize="2xl" fontWeight="bold" marginBottom={4} textAlign="center">
          Pay Membership
        </Text>
        <FormControl isInvalid={!!error}>
          <Select
            placeholder="Select Month"
            options={months}
            onChange={handleMonthChange}
            value={selectedMonth}
            isClearable
          />
          <Select
            placeholder="Select Year"
            options={years}
            onChange={handleYearChange}
            value={selectedYear}
            isClearable
            marginTop={4} // Add spacing between month and year dropdowns
          />
          <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
        <Button colorScheme="teal" width="full" marginTop={4} onClick={handleSubmit} isLoading={isSubmitting}>
          Submit
        </Button>
      </Box>
    </Flex>
  );
};